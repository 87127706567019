import React from 'react'
import Home from '../containers/Home'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'

// export default props => (
//   <Layout {...props}>
//     <SEO
//       title={props.data.lpMetaTitle}
//       description={props.data.lpMetaDescription}
//       lang={props.pageContext.langKey}
//       slugs={{
//         en: '/',
//         fr: '/fr',
//         de: '/de',
//       }}
//     />
    
//     <Home {...props} />    
//   </Layout>
// )

export default () => null

// export const pageQuery = graphql`
//   query HomeDeQuery {
//     site {
//       siteMetadata {
//         languages {
//           defaultLangKey
//           langs
//         }
//       }
//     }
//     mainNav: contentfulNav(
//       name: { eq: "main-top-bar-nav" }
//       node_locale: { eq: "de-CH" }
//     ) {
//       pages {
//         slug
//         nameInNav
//         id
//       }
//     }
//     mainNavLight: contentfulNav(
//       name: { eq: "main-top-bar-nav-light" }
//       node_locale: { eq: "de-CH" }
//     ) {
//       pages {
//         slug
//         nameInNav
//         id
//       }
//     }
//     mainFooterLight: contentfulFooterLight(
//       name: { eq: "footer-light" }
//       node_locale: { eq: "de-CH" }
//     ) {
//       title
//       description
//       ctaButton {
//         title
//         color
//         size
//         visible
//         modal {
//           name
//           title
//           description
//           tagLine
//           emailPlaceholder
//           agreementText
//           linkToPage {
//             slug
//             title
//           }
//           submitButtonText
//           emailErrorMessage
//           tanksTitle
//           thanksDescription
//         }
//       }
//     }
//     headerLight: contentfulHeaderLight(
//       name: { eq: "header-light" }
//       node_locale: { eq: "de-CH" }
//     ) {
//       name
//       ctaButton {
//         title
//         color
//         size
//         modal {
//           name
//           title
//           description
//           tagLine
//           emailPlaceholder
//           agreementText
//           linkToPage {
//             slug
//             title
//           }
//           submitButtonText
//           emailErrorMessage
//           tanksTitle
//           thanksDescription
//         }
//       }
//     }
//     globalConfig: contentfulGlobalConfig {
//    	  id
//       footer
//       header
//     }
//     posts: allContentfulPost(filter: { node_locale: { eq: "de-CH" } }) {
//       edges {
//         node {
//           id
//           node_locale
//           title {
//             title
//           }
//           slug
//         }
//       }
//     }
    
//     data: allContentfulLandingPage(filter: { node_locale: { eq: "de-CH" } }) {
//       edges {
//         node {
//           mainPageMetaTitile
//           mainPageMetaDeacription
//           mainPageMetaImage {
//             file {
//               url
//             }
//           }
//           topScreenSubtitle
//           topScreenTitle
//           topScreenDescription
//           topScreenAvailable
//           appStore {
//             title
//             modal {
//               name
//               title
//               description
//               tagLine
//               emailPlaceholder
//               agreementText
//               linkToPage {
//                 slug
//                 title
//               }
//               submitButtonText
//               emailErrorMessage
//               tanksTitle
//               thanksDescription
//             }
//           }
//           googlePlayButton {
//             title
//             modal {
//               name
//               title
//               description
//               tagLine
//               emailPlaceholder
//               agreementText
//               linkToPage {
//                 slug
//                 title
//               }
//               submitButtonText
//               emailErrorMessage
//               tanksTitle
//               thanksDescription
//             }
//           }
//           topScreenCta {
//             title
//             color
//             size
//             visible
//             modal {
//               name
//               title
//               description
//               tagLine
//               emailPlaceholder
//               agreementText
//               linkToPage {
//                 slug
//                 title
//               }
//               submitButtonText
//               emailErrorMessage
//               tanksTitle
//               thanksDescription
//             }
//           }
//           topScreenImage {
//             id
//             file {
//               url
//               fileName
//               contentType
//             }
//             sizes {
//               base64
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//             }
//           }
//           videoScreenTitle
//           videoScreenImage {
//             id
//             file {
//               url
//               fileName
//               contentType
//             }
//             sizes {
//               base64
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//             }
//           }
//           videoScreenVideoPoster {
//             id
//             file {
//               url
//               fileName
//               contentType
//             }
//             sizes {
//               base64
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//             }
//           }
//           videoScreenVideo {
//             file {
//               url
//               fileName
//               contentType
//             }
//           }
//           tabsTitle
//           tabsDescription {
//             childMarkdownRemark {
//               html
//             }
//           }
//           tabs {
//             id
//             tabTitle
//             tabDescription {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             tabImage {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             ctaButton {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//           }
//           greenScreenTitle {
//             childMarkdownRemark {
//               html
//             }
//           }
//           greenScreenDescription {
//             childMarkdownRemark {
//               html
//             }
//           }
//           greenScreenCtaLink {
//             title
//             color
//             size
//             visible
//             ctaLink {
//               slug
//             }
//           }
//           greenScreenInfo {
//             childMarkdownRemark {
//               html
//             }
//           }
//           greenScreenFirstImage {
//             id
//             file {
//               url
//               fileName
//               contentType
//             }
//             sizes {
//               base64
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//             }
//           }
//           greenScreenSecondImage {
//             id
//             file {
//               url
//               fileName
//               contentType
//             }
//             sizes {
//               base64
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//             }
//           }
//           greenScreenMobileImage {
//             id
//             file {
//               url
//               fileName
//               contentType
//             }
//             sizes {
//               base64
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//             }
//           }
//           greenScreenCards {
//             title
//             description
//             hiddenDescription
//             icon {
//               iconName
//             }
//           }
//           doubleScreenLeft {
//             childMarkdownRemark {
//               html
//             }
//           }
//           doubleScreenRight {
//             childMarkdownRemark {
//               html
//             }
//           }
//           serverHeader {
//             title
//             subtitleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//           ourServer {
//             tagLine
//             title
//             description
//             slides {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//           }
//           verticalScrollScreen {
//             verticalScreen {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             image {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             ctaButton {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//           }
//           whoWeAreTitle {
//             title
//             subtitleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//           whoWeAreBody {
//             titleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             subtitleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//           whoWeAreInfo {
//             firstOfficeTitle
//             firstOfficeDescription
//             secondOfficeTitle
//             secondOfficeDescription
//             companyDescription {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//           locationHeader {
//             title
//             subtitleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//           locationBody {
//             firstTitle
//             firstDescription
//             firstImage {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             } 
//             secondTitle
//             secondDescription
//             secondImage {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             } 
//           }
//           manifesto {
//             iconsTitle
//             info {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//           comparisonTable {
//             titleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             table {
//               tableData
//             }
//             subDescription {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             ctaButton {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//           }
//           benefits {
//             titleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             benefitsImage {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             benefitsVideo {
//               file {
//                 url
//               }
//             }
//             ctaButton {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//             benefitsItems {
//               title {
//                 childMarkdownRemark {
//                   html
//                 }
//               }
//             }
//           }
//           useCases {
//             title
//             useCases {
//               title
//               description
//               ctaButton {
//                 title
//                 color
//                 size
//                 visible
//                 modal {
//                   name
//                   title
//                   description
//                   tagLine
//                   emailPlaceholder
//                   agreementText
//                   linkToPage {
//                     slug
//                     title
//                   }
//                   submitButtonText
//                   emailErrorMessage
//                   tanksTitle
//                   thanksDescription
//                 }
//               }
//             }
//             firstColumnImages {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             secondColumnImages {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             thirdColumnImages {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             fourthColumnImages {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             fifthColumnImages {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//           }
//           plans {
//             titleLong {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             individual {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             individualIndependent {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             enterprise {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             individualCta {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//             teamsCta {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//             enterpriseCta {
//               title
//               color
//               size
//               visible
//               modal {
//                 name
//                 title
//                 description
//                 tagLine
//                 emailPlaceholder
//                 agreementText
//                 linkToPage {
//                   slug
//                   title
//                 }
//                 submitButtonText
//                 emailErrorMessage
//                 tanksTitle
//                 thanksDescription
//               }
//             }
//           }
//           blockChain {
//             title
//             image {
//               id
//               file {
//                 url
//                 fileName
//                 contentType
//               }
//               sizes {
//                 base64
//                 src
//                 srcSet
//                 srcWebp
//                 srcSetWebp
//               }
//             }
//             ico
//             icoCountdownTitle
//             days
//             hours
//             minutes
//             seconds
//             infoLeft {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             infoRight {
//               childMarkdownRemark {
//                 html
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
